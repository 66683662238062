export namespace Brands {
	export enum Janssen {
		NAME = 'Janssen',
		RUT  = '81.198.100-1'
	}

	export enum KREIS {
		NAME = 'Kreis',
		RUT  = '99.560.200-8'
	}
}