// Classes
import { AxiosManager }     from '@/Classes/Network/AxiosManager'
import { DevelopmentTools } from '@/Classes/Static/DevelopmentTools'
import { VuexTools }        from '@/Classes/Static/VuexTools'

// Constants
import { Component } from '@/Constants/Global/Component'
import { Server }    from '@/Constants/Global/Server'

// Dependencies
import Axios from 'axios'

// Interfaces
import { Vuex } from '@/Interfaces/Global/Vuex'

// Store
import Store from '@/Store/Global/Default'

// Aliases
const DataTableConstants = Component.VueComponents.Global.DataTable

// Module Export
export default <VuexStoragesModule<object>> {
	state: {
		response: undefined,
		storagePages: {},
		storageTotalPages: undefined
	},

	mutations: {
		addStorage: function(state, storage) {
			state.response.push(storage)
		},

		destroyStorages: function(state) {
			state.response = undefined
			state.storagePages = {}
			state.storageTotalPages = undefined
		},

		destroyStoragesForPage: function(state, page) {
			delete state.storagePages[`${ page }`]
		},

		storeStorages: function(state, response) {
			state.response = response
		},

		storeStoragesForPage: function(state, response) {
			state.storagePages[`${ response.page }`] = response.data
			state.storageTotalPages = response.totalPages
		},

		updateStorage: function(state, storage) {
			VuexTools.updateRegistry(state, storage, 'response', '_idStorage')
		}
	},

	getters: {
		getStoredStorageById: (state) => (_idStorage: ObjectId) => {
			return state.response?.find((x: any) => x._idStorage === _idStorage)
		},

		getStoredStorages: function(state) {
			return state.response
		},

		getStoredStoragesForPage: (state) => (page: number) => {
			return state.storagePages[`${ page }`]
		},

		getStoredStoragesTotalPages: function(state) {
			return state.storageTotalPages
		}
	},

	actions: {
		fetchStorages: async function({ commit }) {
			try {
				const { _idAdminCompany } = Store.getters.getStoredUser as any
				const response = await Axios.get(Server.Routes.Storages.GetStorages, { headers: AxiosManager.AuthenticationHeader, params: { _idAdminCompany } })
				commit('storeStorages', response.data.body)
				return response.data.body
			}
			catch (err) { DevelopmentTools.printError(err.response) }
		},

		fetchStoragesForPage: async function({ commit }, params) {
			// Verificar que la data correspondiente a la página ya existe.
			const responseFromPage = Store.getters.getStoredStoragesForPage(params.page) as Array<any>
			const totalPages = Store.getters.getStoredStoragesTotalPages as number

			// Obtener desde el Store o realizar Petición.
			if ((!params?.forceRefresh) && (Array.isArray(responseFromPage) && responseFromPage.length > 0)) {
				return { data: responseFromPage, totalPages }
			}

			// Realizar Petición a Servidor.
			try {
				const { _idAdminCompany } = Store.getters.getStoredUser as any
				const _params = { _idAdminCompany, itemsPerPage: DataTableConstants.DefaultValues.ItemsPerPage, page: params.page }
				const response = await Axios.get(Server.Routes.Storages.GetStoragesByPage, { headers: AxiosManager.AuthenticationHeader, params: _params })
				const storages = response.data.body[0]
				commit('storeStoragesForPage', { data: storages.data, page: params.page, totalPages: storages.totalPages })
				return storages
			}
			catch (err) { DevelopmentTools.printError(err.response) }
		}
	}
}

// Module Interfaces
interface VuexStoragesModule<Document, State = VuexStoragesState<Document>, Getters = Vuex.Getters<State>> {
	state: State
	mutations: VuexStoragesMutations<State, Document>
	getters: Getters
	actions: Vuex.Actions<State, Getters>
}

interface VuexStoragesState<Document> {
	response: Array<Document>
	storagePages: { [key: string]: Array<Document> }
	storageTotalPages: number
}

interface VuexStoragesMutations<State, Document> extends Vuex.Mutations<State> {
	addStorage: (state: State, storage: Document) => void
	storeStorages: (state: State, response: Array<Document>) => void
	updateStorage: (state: State, storage: Document) => void
}