// Classes
import { AxiosManager }     from '@/Classes/Network/AxiosManager'
import { DevelopmentTools } from '@/Classes/Static/DevelopmentTools'
import { VuexTools }        from '@/Classes/Static/VuexTools'

// Constants
import { Component } from '@/Constants/Global/Component'
import { Server }    from '@/Constants/Global/Server'

// Dependencies
import Axios from 'axios'

// Interfaces
import { Vuex } from '@/Interfaces/Global/Vuex'
import { User } from '@/Interfaces/Models/User'

// Store
import Store from '@/Store/Global/Default'

// Aliases
const DataTableConstants = Component.VueComponents.Global.DataTable

// Module Export
export default <VuexUsersModule<User>> {
	state: {
		response: undefined,
		externalUserPages: {},
		externalUserTotalPages: undefined,
		internalUserPages: {},
		internalUserTotalPages: undefined
	},

	mutations: {
		addUser: function(state, user) {
			state.response.push(user)
		},

		destroyExternalUsers: function(state) {
			state.externalUserPages = {}
			state.externalUserTotalPages = undefined
		},

		destroyExternalUsersForPage: function(state, page) {
			delete state.externalUserPages[`${ page }`]
		},

		destroyInternalUsers: function(state) {
			state.internalUserPages = {}
			state.internalUserTotalPages = undefined
		},

		destroyInternalUsersForPage: function(state, page) {
			delete state.internalUserPages[`${ page }`]
		},

		destroyUsers: function(state) {
			state.response = undefined
		},

		storeExternalUsersForPage: function(state, response) {
			state.externalUserPages[`${ response.page }`] = response.data
			state.externalUserTotalPages = response.totalPages
		},

		storeInternalUsersForPage: function(state, response) {
			state.internalUserPages[`${ response.page }`] = response.data
			state.internalUserTotalPages = response.totalPages
		},

		storeUsers: function(state, response) {
			state.response = response
		},

		updateUser: function(state, user) {
			VuexTools.updateRegistry(state, user, 'response', '_idUser')
		}
	},

	getters: {
		getStoredExternalUsersForPage: (state) => (page: number) => {
			return state.externalUserPages[`${ page }`]
		},

		getStoredExternalUsersTotalPages: function(state) {
			return state.externalUserTotalPages
		},

		getStoredInternalUsersForPage: (state) => (page: number) => {
			return state.internalUserPages[`${ page }`]
		},

		getStoredInternalUsersTotalPages: function(state) {
			return state.internalUserTotalPages
		},

		getStoredUserById: (state) => (_idUser: ObjectId) => {
			return state.response?.find((x: any) => x._idUser === _idUser)
		},

		getStoredUsers: function(state) {
			return state.response
		}
	},

	actions: {
		fetchUsers: async function({ commit }) {
			try {
				const response = await Axios.get(Server.Routes.Users.GetUsers, VuexTools.getDefaultActionOptions('_idCompany', '_idCompanyAdministrator'))
				commit('storeUsers', response.data.body)
				return response.data.body
			}
			catch (err) { DevelopmentTools.printError(err) }
		},

		fetchUsersForPage: async function({ commit }, params) {
			// Verificar que la data correspondiente a la página ya existe.
			const responseFromPage = Store.getters[`getStored${ params.type }UsersForPage`](params.page) as Array<any>
			const totalPages = Store.getters[`getStored${ params.type }UsersTotalPages`] as number

			// Obtener desde el Store o realizar Petición.
			if ((!params?.forceRefresh) && (Array.isArray(responseFromPage) && responseFromPage.length > 0)) {
				return { data: responseFromPage, totalPages }
			}

			// Realizar Petición a Servidor.
			try {
				const { _idAdminCompany } = Store.getters.getStoredUser as any
				const _params = { _idAdminCompany, isClient: params.isClient || false, itemsPerPage: DataTableConstants.DefaultValues.ItemsPerPage, page: params.page }
				const response = await Axios.get(Server.Routes.Users.GetUsersByPage, { headers: AxiosManager.AuthenticationHeader, params: _params })
				const users = response.data.body[0]
				commit(`store${ params.type }UsersForPage`, { data: users.data, page: params.page, totalPages: users.totalPages })
				return users
			}
			catch (err) { DevelopmentTools.printError(err.response) }
		}
	}
}

// Module Interfaces
interface VuexUsersModule<Document, State = VuexUsersState<Document>, Getters = Vuex.Getters<State>> {
	state: State
	mutations: VuexUsersMutations<State, Document>
	getters: Getters
	actions: Vuex.Actions<State, Getters>
}

interface VuexUsersState<Document> {
	response: Array<Document>
	externalUserPages: { [key: string]: Array<Document> }
	externalUserTotalPages: number
	internalUserPages: { [key: string]: Array<Document> }
	internalUserTotalPages: number
}

interface VuexUsersMutations<State, Document> extends Vuex.Mutations<State> {
	addUser: (state: State, user: Document) => void
	storeUsers: (state: State, response: Array<Document>) => void
	updateUser: (state: State, user: Document) => void
}