// Classes
import { DevelopmentTools } from '@/Classes/Static/DevelopmentTools'
import { WorkerTools }      from '@/Classes/Static/WorkerTools'

// Dependencies
import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
Vue.use(Vuex)

// Interfaces
import { State } from '@/Interfaces/Store/State'

// Store (Global Modules)
import G_Communes      from '@/Store/Global/Communes'
import G_Companies     from '@/Store/Global/Companies'
import G_Components    from '@/Store/Global/Components'
import G_Credentials   from '@/Store/Global/Credentials'
import G_Equipments    from '@/Store/Global/Equipments'
import G_Installations from '@/Store/Global/Installations'
import G_Operators     from '@/Store/Global/Operators'
import G_Permissions   from '@/Store/Global/Permissions'
import G_Regions       from '@/Store/Global/Regions'
import G_Roles         from '@/Store/Global/Roles'
import G_Sockets       from '@/Store/Global/Sockets'
import G_Storages      from '@/Store/Global/Storages'
import G_Systems       from '@/Store/Global/Systems'
import G_Users         from '@/Store/Global/Users'
import G_WorkAreas     from '@/Store/Global/WorkAreas'
import G_Workers       from '@/Store/Global/Workers'
import G_Zones         from '@/Store/Global/Zones'

// Store (System Modules)
import Module4_CheckListAddons       from '@/Store/Modules/4/Addons'
import Module4_CheckListAssociations from '@/Store/Modules/4/Associations'
import Module4_CheckLists            from '@/Store/Modules/4/CheckLists'
import Module4_CheckListSettings     from '@/Store/Modules/4/Settings'

// VuexPersistence
const vuexPersistence = new VuexPersistence<State>({
	storage: window.sessionStorage,
	supportCircular: true,
	reducer: (state) => ({
		G_Components: state.G_Components,
		G_Credentials: state.G_Credentials,
		G_Permissions: state.G_Permissions
	})
})

// Store Instance
const Store = new Vuex.Store<State>({
	state: {
		// Propiedad que almacenará el CancelToken de la ultima Petición realizada por el FrontEnd.
		requestCanceler: undefined,

		// Propiedad que almacenará información que sea recepcionada por el ServiceWorker.
		serviceWorkerPayload: undefined,

		// Propiedad que mantendrá la instancia de Vue de la Vista actual en la que se encuentra el usuario.
		vueInstance: undefined
	},

	mutations: {
		destroyServiceWorkerPayload: function(state) {
			state.serviceWorkerPayload = undefined
		},

		destroyVueInstance: function(state) {
			state.vueInstance = undefined
		},

		storeRequestCanceler: function(state, canceler) {
			state.requestCanceler = canceler
		},

		storeServiceWorkerPayload: function(state, payload) {
			state.serviceWorkerPayload = payload
		},

		storeVueInstance: function(state, instance) {
			state.vueInstance = instance
		}
	},

	getters: {
		getStoredRequestCanceler: function(state) {
			return state.requestCanceler?.cancel
		},

		getStoredServiceWorkerPayload: function(state) {
			return state.serviceWorkerPayload
		},

		getStoredVueInstance: function(state) {
			return state.vueInstance
		}
	},

	actions: {
		onListenToServiceWorkerPayloads: async function({ commit }) {
			if (WorkerTools.isServiceWorkerSupported()) {
				navigator.serviceWorker.addEventListener('message', event => {
					DevelopmentTools.printWarn('[Vuex]: Mensaje de ServiceWorker recibido en el Store:', event.data)
					commit('storeServiceWorkerPayload', event.data)
				})
			}
		},

		onDestroyAllData: async function({ commit }) {
			const keys = [
				'Credentials', 'Companies', 'Equipments', 'Regions', 'Roles',
				'Storages', 'Systems', 'Users', 'UserPermissions', 'WorkAreas'
			]
			for (const key of keys) {
				commit(`destroy${ key }`)
			}
		}
	},

	modules: {
		G_Communes,
		G_Companies,
		G_Components,
		G_Credentials,
		G_Equipments,
		G_Installations,
		G_Operators,
		G_Permissions,
		G_Regions,
		G_Roles,
		G_Sockets,
		G_Storages,
		G_Systems,
		G_Users,
		G_WorkAreas,
		G_Workers,
		G_Zones,
		Module4_CheckListAddons,
		Module4_CheckListAssociations,
		Module4_CheckLists,
		Module4_CheckListSettings
	},

	plugins: [
		vuexPersistence.plugin
	]
})

// Exports
export default Store