
// Classes
import { AxiosManager }     from '@/Classes/Network/AxiosManager'
import { DevelopmentTools } from '@/Classes/Static/DevelopmentTools'
import { VuexTools }        from '@/Classes/Static/VuexTools'

// Constants
import { Component } from '@/Constants/Global/Component'
import { Server }    from '@/Constants/Global/Server'

// Dependencies
import Axios from 'axios'

// Interfaces
import { Vuex } from '@/Interfaces/Global/Vuex'

// Store
import Store from '@/Store/Global/Default'

// Aliases
const DataTableConstants = Component.VueComponents.Global.DataTable

// Module Export
export default <VuexZonesModule<any>> {
	state: {
		assignedEquipmentPages: {},
		assignedEquipmentTotalPages: undefined,
		zonePages: {},
		zoneTotalPages: undefined
	},

	mutations: {
		destroyAssignedEquipments: function(state) {
			state.assignedEquipmentPages = {}
			state.assignedEquipmentTotalPages = undefined
		},

		destroyAssignedEquipmentsForPage: function(state, page) {
			delete state.assignedEquipmentPages[`${ page }`]
		},

		destroyZones: function(state) {
			state.zonePages = {}
			state.zoneTotalPages = undefined
		},

		destroyZonesForPage: function(state, page) {
			delete state.zonePages[`${ page }`]
		},

		storeAssignedEquipmentsForPage: function(state, response) {
			state.assignedEquipmentPages[`${ response.page }`] = response.data
			state.assignedEquipmentTotalPages = response.totalPages
		},

		storeZonesForPage: function(state, response) {
			state.zonePages[`${ response.page }`] = response.data
			state.zoneTotalPages = response.totalPages
		}
	},

	getters: {
		getStoredAssignedEquipmentsForPage: (state) => (page: number) => {
			return state.assignedEquipmentPages[`${ page }`]
		},

		getStoredAssignedEquipmentsTotalPages: function(state) {
			return state.assignedEquipmentTotalPages
		},

		getStoredZonesForPage: (state) => (page: number) => {
			return state.zonePages[`${ page }`]
		},

		getStoredZonesTotalPages: function(state) {
			return state.zoneTotalPages
		}
	},

	actions: {
		fetchAssignedEquipmentsForPage: async function({ commit }, params) {
			// Verificar que la data correspondiente a la página ya existe.
			const responseFromPage = Store.getters.getStoredAssignedEquipmentsForPage(params.page) as Array<any>
			const totalPages = Store.getters.getStoredAssignedEquipmentsTotalPages as number

			// Obtener desde el Store o realizar Petición.
			if ((!params?.forceRefresh) && (Array.isArray(responseFromPage) && responseFromPage.length > 0)) {
				return { data: responseFromPage, totalPages }
			}

			// Realizar Petición a Servidor.
			try {
				const _params = { _idStorage: params._idStorage, itemsPerPage: DataTableConstants.DefaultValues.ItemsPerPage, page: params.page }
				const response = await Axios.get(Server.Routes.Zones.GetEquipmentsForStorage, { headers: AxiosManager.AuthenticationHeader, params: _params })
				const equipments = response.data.body[0]
				commit('storeAssignedEquipmentsForPage', { data: equipments.data, page: params.page, totalPages: equipments.totalPages })
				return equipments
			}
			catch (err) { DevelopmentTools.printError(err.response) }
		},

		fetchZonesForPage: async function({ commit }, params) {
			// Verificar que la data correspondiente a la página ya existe.
			const responseFromPage = Store.getters.getStoredZonesForPage(params.page) as Array<any>
			const totalPages = Store.getters.getStoredZonesTotalPages as number

			// Obtener desde el Store o realizar Petición.
			if ((!params?.forceRefresh) && (Array.isArray(responseFromPage) && responseFromPage.length > 0)) {
				return { data: responseFromPage, totalPages }
			}

			// Realizar Petición a Servidor.
			try {
				const _params = { _idUser: params._idUser, itemsPerPage: DataTableConstants.DefaultValues.ItemsPerPage, page: params.page }
				const response = await Axios.get(Server.Routes.Zones.GetZonesByUser, { headers: AxiosManager.AuthenticationHeader, params: _params })
				const zones = response.data.body[0]
				commit('storeZonesForPage', { data: zones.data, page: params.page, totalPages: zones.totalPages })
				return zones
			}
			catch (err) { DevelopmentTools.printError(err.response) }
		}
	}
}

// Module Interfaces
interface VuexZonesModule<Document, State = VuexZonesState<Document>, Getters = Vuex.Getters<State>> {
	state: State
	mutations: VuexZonesMutations<State, Document>
	getters: Getters
	actions: Vuex.Actions<State, Getters>
}

interface VuexZonesState<Document> {
	assignedEquipmentPages: { [key: string]: Array<Document> }
	assignedEquipmentTotalPages: number
	zonePages: { [key: string]: Array<Document> }
	zoneTotalPages: number
}

interface VuexZonesMutations<State, Document> extends Vuex.Mutations<State> {
	storeZonesForPage: (state: State, response: { data: Array<Document>, page: number, totalPages: number }) => void
}