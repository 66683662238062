// Constants
import { Brands } from '@/Constants/Global/Companies'

export namespace Documents {
	export const Roles = {
		[Brands.Janssen.NAME]: {
			CLIENT: '',
			ROOT  : ''
		},
		[Brands.KREIS.NAME]: {
			CLIENT: '62e056b95ca2836164f6ad0d',
			ROOT  : '5f297763e0c6b880d8f513f6'
		}
	}

	export const WorkAreas = {
		[Brands.Janssen.NAME]: {
			CLIENT: '',
			ROOT: ''
		},
		[Brands.KREIS.NAME]: {
			CLIENT: '606a45113bcb3d3ab8fc1ca1',
			ROOT: '5ef9185cca165f8d5197d7ca'
		}
	}
}