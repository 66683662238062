// Classes
import { VuexTools } from '@/Classes/Static/VuexTools'

// Interfaces
import { Vuex } from '@/Interfaces/Global/Vuex'

// Module Export
export default <VuexComponentsModule> {
	state: {
		SideMenu: {
			class: 'is-maximized',
			isMinimized: false
		},
		Views: {
			viewMode: undefined
		}
	},

	mutations: {
		updateSideMenuIsMinimized: function(state, minimized) {
			state.SideMenu.isMinimized = minimized
			VuexTools.updateComponentClassAnimation(state, 'SideMenu', minimized, 'is-minimized', 'is-maximized', 333)
		},

		updateViewsViewMode: function(state, mode) {
			state.Views.viewMode = mode
		}
	},

	getters: {
		getStoredSideMenu: function(state) {
			return state.SideMenu
		},

		getStoredViews: function(state) {
			return state.Views
		}
	}
}

// Module Interfaces
interface VuexComponentsModule<State = VuexComponentsState> {
	state: State
	mutations: VuexComponentsMutations<State>
	getters: Vuex.Getters<State>
}

export interface VuexComponentsState {
	SideMenu: { class: string, isMinimized: boolean }
	Views: { viewMode: Number }
}

interface VuexComponentsMutations<State> extends Vuex.Mutations<State> {
	updateSideMenuIsMinimized: (state: State, minimized: boolean) => void
}