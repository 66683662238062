// Dependencies
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Vue from 'vue'
Vue.component('fa', FontAwesomeIcon)

// Solid Icons
import {
	/* A */ faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight, faArrowAltCircleLeft, faArrowAltCircleRight,
	/* B */ faBarcode, faBars, faBell, faBriefcase, faBookReader, faBug, faBuilding,
	/* C */ faCamera, faCaretDown, faChartPie, faCheckCircle, faChevronCircleDown, faChevronDown, faChevronUp, faClipboard, faClipboardCheck, faClipboardList, faCogs,
	/* D */ faDiceD6,
	/* E */ faEdit, faEye, faEyeSlash, faExclamation, faExclamationCircle, faExternalLinkSquareAlt,
	/* F */ faFile, faFileAlt, faFileCsv, faFileExcel as fasFileExcel, faFileInvoice, faFilePdf as fasFilePdf, faFileUpload,
	/* H */ faHandshake, faHistory,
	/* I */ faInfoCircle,
	/* L */ faLayerGroup, faList,
	/* M */ faMapMarkerAlt, faMinus, faMinusCircle,
	/* P */ faPaperclip, faPen, faPlus,
	/* Q */ faQrcode,
	/* S */ faSearch, faShippingFast, faSignOutAlt, faSquare, faSyncAlt,
	/* T */ faTasks, faThList, faTimes, faTimesCircle, faTools, faTrashAlt, faTruckPickup,
	/* U */ faUser, faUsersCog, faUserPlus, faUserTag,
	/* W */ faWarehouse, faWrench
} from '@fortawesome/free-solid-svg-icons'

// Regular Icons
import {
	/* C */ faCalendarAlt, faClipboard as farClipboard,
	/* F */ faFileExcel, faFilePdf,
	/* I */ faImage,
	/* L */ faListAlt,
	/* T */ faTrashAlt as farTrashAlt,
	/* W */ faWindowClose
} from '@fortawesome/free-regular-svg-icons'

library.add(
	/* A */ faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight, faArrowAltCircleLeft, faArrowAltCircleRight,
	/* B */ faBarcode, faBars, faBell, faBriefcase, faBookReader, faBug, faBuilding,
	/* C */ faCalendarAlt, faCaretDown, faChartPie, faChevronCircleDown, faChevronDown, faChevronUp, faCamera, faCheckCircle, faClipboard, farClipboard, faClipboardCheck, faClipboardList, faCogs,
	/* D */ faDiceD6,
	/* E */ faEdit, faEye, faEyeSlash, faExclamation, faExclamationCircle, faExternalLinkSquareAlt,
	/* F */ faFile, faFileAlt, faFileCsv, faFileExcel, fasFileExcel, faFileInvoice, faFilePdf, fasFilePdf, faFileUpload,
	/* H */ faHandshake, faHistory,
	/* I */ faImage, faInfoCircle,
	/* L */ faLayerGroup, faList, faListAlt,
	/* M */ faMapMarkerAlt, faMinus, faMinusCircle,
	/* P */ faPaperclip, faPen, faPlus,
	/* Q */ faQrcode,
	/* S */ faSearch, faShippingFast, faSignOutAlt, faSquare, faSyncAlt,
	/* T */ faTasks, faThList, faTimes, faTimesCircle, faTools, faTrashAlt, farTrashAlt, faTruckPickup,
	/* U */ faUser, faUsersCog, faUserPlus, faUserTag,
	/* W */ faWarehouse, faWindowClose, faWrench
)