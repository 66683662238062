// Classes
import { DevelopmentTools } from '@/Classes/Static/DevelopmentTools'
import { VuexTools }        from '@/Classes/Static/VuexTools'

// Constants
import { Server } from '@/Constants/Global/Server'

// Dependencies
import Axios from 'axios'

// Interfaces
import { Vuex } from '@/Interfaces/Global/Vuex'

// Store
import Store from '@/Store/Global/Default'

// Module Export
export default <VuexStoragesModule> {
	actions: {
		initServiceWorkerChannel: async function({ state }) {
			const channel = new BroadcastChannel('sw')
			channel.addEventListener('message', (e) => {
				console.log(e.data)
			})
		}
	}
}

// Module Interfaces
interface VuexStoragesModule {
	actions: Vuex.Actions<{}, {}>
}