// Classes
import { AxiosManager }     from '@/Classes/Network/AxiosManager'
import { DevelopmentTools } from '@/Classes/Static/DevelopmentTools'

// Constants
import { Server } from '@/Constants/Global/Server'

// Dependencies
import Axios from 'axios'

// Interfaces
import { Vuex } from '@/Interfaces/Global/Vuex'

// Module Export
export default <VuexRegionsModule<object>> {
	state: {
		regions: undefined
	},

	mutations: {
		destroyRegions: function(state) {
			state.regions = undefined
		},
		
		storeRegions: function(state, regions) {
			state.regions = regions
		}
	},

	getters: {
		getStoredRegions: function(state) {
			return state.regions
		}
	},

	actions: {
		fetchRegions: async function({ commit }) {
			try {
				const response = await Axios.get(Server.Routes.Communes.GetCommunes, { headers: AxiosManager.AuthenticationHeader })
				commit('storeRegions', response.data.body)
				return response.data.body
			}
			catch (err) { DevelopmentTools.printError(err.response) }
		}
	}
}

// Module Interfaces
interface VuexRegionsModule<Document, State = VuexRegionsState<Document>, Getters = Vuex.Getters<State>> {
	state: State
	mutations: VuexRegionsMutations<State, Document>
	getters: Getters
	actions: Vuex.Actions<State, Getters>
}

interface VuexRegionsState<Document> {
	regions: Array<Document>
}

interface VuexRegionsMutations<State, Document> extends Vuex.Mutations<State> {
	storeRegions: (state: State, regions: Array<Document>) => void
}