// Classes
import { VuexTools } from '@/Classes/Static/VuexTools'

// Interfaces
import { Vuex } from '@/Interfaces/Global/Vuex'

// Module Export
export default <VuexAssociationsModule<any>> {
	state: {
		addons: undefined,
		facilities: undefined,
		addonsPages:{},
		addonsTotalPages: undefined,
		facilitiesPages:{},
		facilitiesTotalPages:undefined

	},

	mutations: {
		destroyAddonsAssociations: function(state) {
			state.addons = undefined
		},

		storeAddonsAssociations: function(state, associations) {
			state.addons = associations
		},

		destroyFacilityAssociations: function(state) {
			state.facilities = undefined
		},

		storeFacilityAssociations: function(state, facilities) {
			state.facilities = facilities
		},

		destroyAddons: function(state) {
			state.addonsPages={}
			delete state.addonsTotalPages
		},

		storeAddonsAssociationsForPage: function(state, response){
			state.addonsPages[`${response.page}`] = response.data
			state.addonsTotalPages = response.totalPages
		},

		destroyFacilities: function(state) {
			state.facilitiesPages={}
			delete state.facilitiesTotalPages
		},

		storeFacilitiesAssociationsForPage: function(state, response){
			state.facilitiesPages[`${response.page}`] = response.data
			state.facilitiesTotalPages = response.totalPages
		},


	},

	getters: {
		getStoredAddonsAssociationsById: (state) => (idKey: string, _id: ObjectId) => {
			return state.addons?.find((x: any) => x[idKey] === _id)
		},

		getStoredAddonsAssociations: function(state) {
			return state.addons
		},

		getStoredFacilityAssociationsById: (state) => (idKey: string, _id: ObjectId) => {
			return state.facilities?.find((x: any) => x[idKey] === _id)
		},

		getStoredFacilityAssociations: function(state) {
			return state.facilities
		},

		getStoredAddonsAssociationsForPage: (state) =>(page:number) => {
			return state.addonsPages[`${ page }`]
		},

		getStoredAddonsAssociationsTotalPages: (state) => (page: number) =>{
			return state.addonsTotalPages
		},

		getStoredFacilitiesAssociationsForPage: (state) =>(page:number) => {
			return state.facilitiesPages[`${ page }`]
		},

		getStoredFacilitiesAssociationsTotalPages: (state) => (page: number) =>{
			return state.facilitiesTotalPages
		}
	}
}

// Module Interfaces
interface VuexAssociationsModule<Document, State = VuexAssociationsState<Document>> {
	state: State
	mutations: VuexAssociationsMutations<State, Document>
	getters: Vuex.Getters<State>
}

interface VuexAssociationsState<Document> {
	addons: Array<Document>
	facilities: Array<Document>
	addonsPages: { [key: string]: Array<Document> }
	addonsTotalPages: number
	facilitiesPages: { [key: string]: Array<Document> }
	facilitiesTotalPages: number


}

interface VuexAssociationsMutations<State, Document> extends Vuex.Mutations<State> {
	storeAddonsAssociations: (state: State, addon: Document) => void
	storeAddonsAssociationsForPage: (state: State, response: { data: Array<Document>, page: number, totalPages: number }) => void
	storeFacilitiesAssociationsForPage: (state: State, response: { data: Array<Document>, page: number, totalPages: number }) => void
}