// Interfaces
import { Vuex } from '@/Interfaces/Global/Vuex'
import { TokenizedUser } from '@/Interfaces/Models/TokenizedUser'

// Store
import Store from '@/Store/Global/Default'

// Module Export
export default <VuexCredentialsModule> {
	state: {
		accessToken: undefined,
		permissions: undefined,
		user: undefined
	},

	mutations: {
		destroyAccessToken: function(state) {
			state.accessToken = undefined
		},

		destroyCredentials: function(state) {
			state.accessToken = undefined
			state.user = undefined
		},

		destroyUserPermissions: function(state) {
			state.permissions = undefined
		},

		storeCredentials: function(state, credentials) {
			state.accessToken = credentials.accessToken
			state.user = credentials.user
		},

		storeUserPermissions: function(state, permissions) {
			state.permissions = permissions
		}
	},

	getters: {
		getStoredAccessToken: function(state) {
			return state.accessToken
		},

		getStoredUser: function(state) {
			return state.user
		},

		getStoredUserPermissions: function(state) {
			return state.permissions
		},

		getStoredUserPermissionsAsObject: function(state) {
			const permissions: Array<any> = Store.getters.getStoredPermissions
			const userPermissions = state.permissions
			const parsed: any = {}
			permissions?.map((p) => {
				const privileges = userPermissions.find((x) => x._id === p._idPermission)
				parsed[p.action] = { _idPermission: p._idPermission, privileges: privileges?.privileges || {} }
			})
			return parsed
		}
	}
}

// Module Interfaces
interface VuexCredentialsModule<State = VuexCredentialsState> {
	state: State
	mutations: VuexCredentialsMutations<State>
	getters: Vuex.Getters<State>
}

export interface VuexCredentialsState {
	accessToken: AccessToken
	permissions: Array<any>
	user: TokenizedUser
}

interface VuexCredentialsMutations<State> extends Vuex.Mutations<State> {
	storeCredentials: (state: State, credentials: { accessToken: AccessToken, user: TokenizedUser }) => void
}

export interface VuexCredentialsGetters {
	getStoredAccessToken: VuexCredentialsState['accessToken']
	getStoredUser: VuexCredentialsState['user']
	getStoredUserPermissions: VuexCredentialsState['permissions']
	getStoredUserPermissionsAsObject: any
}