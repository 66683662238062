export namespace Component {
	export enum Actions {
		INSERT,
		READ,
		UPDATE
	}

	export namespace VueComponents {
		export namespace Global {
			export namespace DataTable {
				export const DefaultValues = {
					ItemsPerPage: 30,
					NumPaginationButtons: 10
				}

				export const DefaultThStyle = {
					backgroundColor: '#4C78B3',
					border: '1px solid #3D608F'
				}
			}
		}
	}
}