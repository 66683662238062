// ./
import Config from '@/config'
const { protocol, port, hostname }: any = Config.axios

// Classes
import { DevelopmentTools } from '@/Classes/Static/DevelopmentTools'

// Dependencies
import io from 'socket.io-client'

// Module Export
export default {
	state: {
		// Propiedad que almacenará información relacionada a la
		// conexión por medio de Socket.IO del usuario.
		socket: undefined,

		// Propiedad que almacenará información enviada por el Servidor,
		// esta propiedad será utilizada para realizar actualizaciones
		// al cliente en tiempo real.
		socketPayload: undefined
	},

	mutations: {
		storeSocket: function(state: any, socket: any) {
			state.socket = socket
		},

		storeSocketPayload: function(state: any, payload: any) {
			state.socketPayload = payload
		}
	},

	getters: {
		getStoredSocket: function(state: any) {
			return state.socket
		}
	},

	actions: {
		connectToSocketIO: async function({ commit, getters }: any) {
			const socket = io(`${protocol}://${hostname}:${port}/`, { transports: ['polling'] })
			commit('storeSocket', socket)

			// Evento de Bienvenida.
			socket.on('welcome', (id: string) => DevelopmentTools.printWarn(`[Vuex]: Cliente '${ id }' conectado a Servidor con Socket.IO`))

			// Emición de Evento para guardar la información del usuario (Si se presiona F5).
			const user = getters.getStoredUser
			if (user) socket.emit('userLoggedIn', { channel: 'web', user })
		}
	}
}