// Classes
import { VuexTools } from '@/Classes/Static/VuexTools'

// Interfaces
import { Vuex } from '@/Interfaces/Global/Vuex'

// Module Export
export default <VuexAddonsModule<any>> {
	state: {
		response: undefined,
		addonPages: {},
		addonTotalPages: undefined
	},

	mutations: {
		addAddon: function(state, addon) {
			state.response.push(addon)
		},

		destroyAddons: function(state) {
			state.addonPages={}
			delete state.addonTotalPages
		},

		destroyAddonsForPage: function(state, page){
			delete state.addonPages[`${ page }`]
			delete state.addonTotalPages
		},

		storeAddons: function(state, response) {
			state.response = response
		},

		storeAddonsForPage: function(state, response){
			state.addonPages[`${response.page}`] = response.data
			state.addonTotalPages = response.totalPages
		},

		updateAddon: function(state, addon) {
			VuexTools.updateRegistry(state, addon, 'response', '_idCheckListAddons')
		}
	},

	getters: {
		getStoredAddonsById: (state) => (_idCheckListAddons: ObjectId) => {
			return state.response?.find((x: any) => x._idCheckListAddons === _idCheckListAddons)
		},

		getStoredAddons: function(state) {
			return state.response
		},

		getStoredAddonsForPage: (state) =>(page:number) => {
			return state.addonPages[`${ page }`]
		},

		getStoredAddonsTotalPages: (state) => (page: number) =>{
			return state.addonTotalPages
		}
	}
}

// Module Interfaces
interface VuexAddonsModule<Document, State = VuexAddonsState<Document>> {
	state: State
	mutations: VuexAddonsMutations<State, Document>
	getters: Vuex.Getters<State>
}

interface VuexAddonsState<Document> {
	response: Array<Document>
	addonPages: { [key: string]: Array<Document> }
	addonTotalPages: number
}

interface VuexAddonsMutations<State, Document> extends Vuex.Mutations<State> {
	addAddon: (state: State, addon: Document) => void
	storeAddons: (state: State, response: Array<Document>) => void
	storeAddonsForPage: (state: State, response: { data: Array<Document>, page: number, totalPages: number }) => void
	updateAddon: (state: State, addon: Document) => void
}