// Dependencies
import { jsPDF } from 'jspdf'

// Fonts
import OpenSansBold from '@/Fonts/JsPDF/OpenSans/Open-Sans-Bold'
import OpenSansNormal from '@/Fonts/JsPDF/OpenSans/Open-Sans-Normal'

// Export Function
export function registerNewJsPDFonts() {
	const Self: any = {
		registerNewFonts: function() {
			// OpenSans-Normal
			this.addFileToVFS('OpenSans-Regular-normal.ttf', OpenSansNormal)
			this.addFont('OpenSans-Regular-normal.ttf', 'OpenSans-Normal', 'normal')

			// OpenSans-Bold
			this.addFileToVFS('OpenSans-Bold-bold.ttf', OpenSansBold)
			this.addFont('OpenSans-Bold-bold.ttf', 'OpenSans-Bold', 'normal')
		}
	}
	jsPDF.API.events.push(['addFonts', Self.registerNewFonts])		
}