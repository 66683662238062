// Classes
import { AxiosManager }     from '@/Classes/Network/AxiosManager'
import { DevelopmentTools } from '@/Classes/Static/DevelopmentTools'

// Constants
import { Server } from '@/Constants/Global/Server'

// Dependencies
import Axios from 'axios'

// Interfaces
import { Vuex } from '@/Interfaces/Global/Vuex'

// Module Export
export default <VuexCommunesModule> {
	actions: {
		fetchCommunes: async function(_, params) {
			try {
				const response = await Axios.get(Server.Routes.Communes.GetCommunesByRegion, { headers: AxiosManager.AuthenticationHeader, params: { region: params.region } })
				const { communes } = response.data.body[0]
				return communes?.map((x: string) => x.toUpperCase())
			}
			catch (err) { DevelopmentTools.printError(err) }
		}
	}
}

// Module Interfaces
interface VuexCommunesModule {
	actions: Vuex.Actions<{}, {}>
}