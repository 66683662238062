// Plugin Export
export const LegendExtraHeightSpacing = {
	id: 'LegendExtraHeightSpacing',
	beforeInit: (chart: any) => {
		const fitValue = chart.legend.fit
		chart.legend.fit = function() {
			fitValue.bind(chart.legend)()
			return this.height += 24
		}
	}
}