export class DevelopmentTools {
	static readonly isDevelopment: boolean = process.env.NODE_ENV === 'development'

	static printError(...data: Array<any>) {
		if (DevelopmentTools.isDevelopment) console.error(...data)
	}
	
	static printWarn(...data: Array<any>) {
		if (DevelopmentTools.isDevelopment) console.warn(...data)
	}
}