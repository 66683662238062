// Classes
import { AxiosManager }     from '@/Classes/Network/AxiosManager'
import { DevelopmentTools } from '@/Classes/Static/DevelopmentTools'
import { VuexTools }        from '@/Classes/Static/VuexTools'

// Constants
import { Server } from '@/Constants/Global/Server'

// Dependencies
import Axios from 'axios'

// Interfaces
import { Vuex }     from '@/Interfaces/Global/Vuex'
import { Operator } from '@/Interfaces/Models/Operator'

// Module Export
export default <VuexOperatorsModule<Operator>> {
	state: {
		operatorsPage: undefined,
		operatorTotalPages: undefined
	},

	mutations: {
		addOperator: function(state, operator) {
			state.operatorsPage.push(operator)
		},

		destroyOperators: function(state) {
			state.operatorsPage = undefined,
			state.operatorTotalPages= undefined
		},

		destroyOperatorsForPage: function(state) {
			state.operatorsPage = [];
		},

		storeOperators: function(state, operatorsPage) {
			state.operatorsPage = operatorsPage
		},

		storeOperatorsForPage: function(state, response) {
		 	state.operatorsPage[response.page]= response.data[1]
		 	state.operatorTotalPages = response.totalPages
		},

		updateOperator: function(state, operator) {
			VuexTools.updateRegistry(state, operator, 'operatorsPage', '_idOperator')
		}
	},

	getters: {
		getStoredOperatorById: (state) => (_idOperator: ObjectId) => {
			return state.operatorsPage?.find((x: any) => x._idOperator === _idOperator)
		},

		getStoredOperators: function(state) {
			return state.operatorsPage
		},
		getStoredOperatorsTotalPages: function(state) {
			return state.operatorTotalPages
		},
	},

	actions: {
		fetchAllStoragesOperators: async function({ commit }, params) {
			try {
				const headers = AxiosManager.AuthenticationHeader
				const _params = { _idsStorages: params._idStorages, page: params.page, itemsPerPage:params.itemsPerPage }
				const response = await Axios.get(Server.Routes.Operators.GetOperators, { headers, params: _params })
				commit('storeOperators', response.data.body)
				return response.data.body
			}
			catch (err) { DevelopmentTools.printError(err.response) }
		}
	}
}

// Module Interfaces
interface VuexOperatorsModule<Document, State = VuexOperatorsState<Document>, Getters = Vuex.Getters<State>> {
	state: State
	mutations: VuexOperatorsMutations<State, Document>
	getters: Getters
	actions: Vuex.Actions<State, Getters>
}

interface VuexOperatorsState<Document> {
	operatorsPage: Array<Document>
	operatorTotalPages: number
}

interface VuexOperatorsMutations<State, Document> extends Vuex.Mutations<State> {
	addOperator: (state: State, operator: Document) => void
	storeOperators: (state: State, operator: Array<Document>) => void
	updateOperator: (state: State, operator: Document) => void
	storeOperatorsForPage: (state: State, response: { data: Array<Document>, page: number, totalPages: number }) => void
}