// Constants
import { VueRouter } from '@/Constants/Global/VueRouter'

// Export Routes
export const GlobalRoutes = [
	{
		path: VueRouter.Global.View00.PATH,
		name: VueRouter.Global.View00.NAME,
		component: () => import(/* webpackChunkName: "0_00" */ '@/Views/Global/0/00/template.vue')
	},
	{
		path: VueRouter.Global.View01.PATH,
		name: VueRouter.Global.View01.NAME,
		component: () => import(/* webpackChunkName: "0_01" */ '@/Views/Global/0/01/template.vue')
	},
	{
		path: VueRouter.Global.View02.PATH,
		name: VueRouter.Global.View02.NAME,
		component: () => import(/* webpackChunkName: "0_02" */ '@/Views/Global/0/02/template.vue')
	}
]