// Classes
import { DevelopmentTools } from '@/Classes/Static/DevelopmentTools'

// Dependencies
import runtime from 'serviceworker-webpack-plugin/lib/runtime'

// Store
import Store from '@/Store/Global/Default'

// Class Export
export class WorkerTools {
	static isServiceWorkerSupported() {
		const isSupported = 'serviceWorker' in navigator
		if (!isSupported) {
			DevelopmentTools.printError('[ServiceWorkerUtils] La Implementación de la API de ServiceWorker no esta soportada por el Navegador!')
		}
		return isSupported
	}

	static registerServiceWorker() {
		if (WorkerTools.isServiceWorkerSupported()) {
			const registered = runtime.register()
			if (registered) registered.then(() => Store.dispatch('onListenToServiceWorkerPayloads'))
		}
	}
	
	static sendMessageToServiceWorker(action: string, payload: any) {
		if (WorkerTools.isServiceWorkerSupported()) {
			navigator.serviceWorker.ready.then((registration) => {
				const message: { action: string, payload: any } = { action, payload }
				registration.active.postMessage(message)
			})
		}
	}
}