// Classes
import { VuexTools } from '@/Classes/Static/VuexTools'

// Interfaces
import { Vuex } from '@/Interfaces/Global/Vuex'

// Module Export
export default <VuexInstallationsModule<any>> {
	state: {
		installations: undefined,
		installationsPage: {},
		installationsTotalPages: undefined
	},

	mutations: {
		addInstallation: function(state, installation) {
			state.installations.push(installation)
		},

		destroyInstallations: function(state) {
			state.installations = undefined
		},

		destroyInstallationsForPage: function(state, page){
			delete state.installationsPage[`${ page }`]
			delete state.installationsTotalPages
		},

		storeInstallations: function(state, installations) {
			state.installations = installations
		},

		storeInstallationsForPage: function(state, response){
			state.installationsPage[`${response.page}`] = response.data
			state.installationsTotalPages = response.totalPages
		},

		updateInstallation: function(state, installation) {
			VuexTools.updateRegistry(state, installation, 'installations', '_idInstallation')
		}
	},

	getters: {
		getStoredInstallationById: (state) => (_idInstallation: ObjectId) => {
			return state.installations?.find((x: any) => x._idInstallation === _idInstallation)
		},

		getStoredInstallations: function(state) {
			return state.installations
		},

		getStoredInstallationsForPage: (state) =>(page:number) => {
			return state.installationsPage[`${ page }`]
		},

		getStoredInstallationsTotalPages: (state) => (page: number) =>{
			return state.installationsTotalPages
		},
	}
}

// Module Interfaces
interface VuexInstallationsModule<Document, State = VuexInstallationsState<Document>, Getters = Vuex.Getters<State>> {
	state: State
	mutations: VuexInstallationsMutations<State, Document>
	getters: Getters
}

interface VuexInstallationsState<Document> {
	installations: Array<Document>
	installationsPage: { [key: string]: Array<Document> }
	installationsTotalPages: number
}

interface VuexInstallationsMutations<State, Document> extends Vuex.Mutations<State> {
	addInstallation: (state: State, installation: Document) => void
	storeInstallations: (state: State, installation: Array<Document>) => void
	storeInstallationsForPage:  (state: State, response: { data: Array<Document>, page: number, totalPages: number }) => void
	updateInstallation: (state: State, installation: Document) => void
}