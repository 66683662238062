// Classes
import { VuexTools } from '@/Classes/Static/VuexTools'

// Interfaces
import { Vuex } from '@/Interfaces/Global/Vuex'

// Module Export
export default <VuexCheckListsModule<any>> {
	state: {
		checkListPages: {},
		checkListTotalPages: undefined,
		equipmentPages: {},
		equipmentTotalPages: undefined
	},

	mutations: {
		destroyCheckLists: function(state) {
			state.checkListPages = {}
			state.checkListTotalPages = undefined
		},

		destroyCheckListsForPage: function(state, page) {
			delete state.checkListPages[`${ page }`]
		},

		destroyEquipments: function(state) {
			state.equipmentPages = {}
			state.equipmentTotalPages = undefined
		},

		storeCheckListsForPage: function(state, response) {
			state.checkListPages[`${ response.page }`] = response.data
			state.checkListTotalPages = response.totalPages
		},

		storeEquipmentsForPage: function(state, response) {
			state.equipmentPages[`${ response.page }`] = response.data
			state.equipmentTotalPages = response.totalPages
		}
	},

	getters: {
		getStoredCheckListsForPage: (state) => (page: number) => {
			return state.checkListPages[`${ page }`]
		},

		getStoredCheckListsTotalPages: function(state) {
			return state.checkListTotalPages
		},

		getStoredCheckListEquipmentsForPage: (state) => (page: number) => {
			return state.equipmentPages[`${ page }`]
		},

		getStoredCheckListEquipmentsTotalPages: function(state) {
			return state.equipmentTotalPages
		}
	}
}

// Module Interfaces
interface VuexCheckListsModule<Document, State = VuexCheckListsState<Document>> {
	state: State
	mutations: VuexCheckListsMutations<State, Document>
	getters: Vuex.Getters<State>
}

interface VuexCheckListsState<Document> {
	checkListPages: { [key: string]: Array<Document> }
	checkListTotalPages: number
	equipmentPages: { [key: string]: Array<Document> }
	equipmentTotalPages: number
}

interface VuexCheckListsMutations<State, Document> extends Vuex.Mutations<State> {
	storeCheckListsForPage: (state: State, response: { data: Array<Document>, page: number, totalPages: number }) => void
	storeEquipmentsForPage: (state: State, response: { data: Array<Document>, page: number, totalPages: number }) => void
}